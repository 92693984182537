import React from "react"
import Layout from "../components/Layout"
import ContentBlock from "../components/ContentBlock"

const PageSupport = () => (
  <Layout>
    <ContentBlock
      containerClassName="flex-1 flex"
      className="flex flex-1 flex-col justify-center"
    >
      <div className="text-center mt-4 lg:mt-8">
        <div className="text-yellow-500 text-5xl md:text-6xl text-300">
          Uh-oh!
        </div>
        <div className="text-xl md:text-2xl">
          I can&apos;t seem to find that page...
        </div>
        <div className="w-4/5 md:max-w-lg mx-auto mt-6 md:mt-12">
          <img src="/bill.png" alt="Bill the dog" className="md:ml-12" />
        </div>
      </div>
    </ContentBlock>
  </Layout>
)

export default PageSupport
